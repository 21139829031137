import './App.css';

import PaverLayout from './PaverLayout/PaverLayout';

function App() {
  return (
    <div className="App">
      <PaverLayout />
    </div>
  );
}

export default App;
