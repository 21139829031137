import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";

// Initial sample data representing different sizes of pavers
const initialPavers = [
  { id: 1, width: 240, height: 180 },
  { id: 2, width: 240, height: 360 },
  { id: 3, width: 240, height: 300 },
  { id: 4, width: 120, height: 300 },
  { id: 5, width: 120, height: 120 },
  { id: 6, width: 120, height: 180 },
  { id: 7, width: 180, height: 300 },
  { id: 8, width: 180, height: 180 },
  { id: 9, width: 180, height: 240 },
];

const PaverLayout = () => {
  const [pavers, setPavers] = useState(initialPavers);
  const [newPaver, setNewPaver] = useState({ width: "", height: "" });
  const [largeRectangle, setLargeRectangle] = useState({
    width: 1000,
    height: 1000,
  });
  const [tempRectangle, setTempRectangle] = useState({
    width: 1000,
    height: 1000,
  });

  const handleAddPaver = () => {
    if (newPaver.width && newPaver.height) {
      const newPaverItem = {
        id: pavers.length + 1,
        width: parseInt(newPaver.width),
        height: parseInt(newPaver.height),
      };
      setPavers([...pavers, newPaverItem]);
      setNewPaver({ width: "", height: "" });
    }
  };

  const handleRemovePaver = (id) => {
    setPavers(pavers.filter((paver) => paver.id !== id));
  };

  const handleRotatePaver = (id) => {
    setPavers(
      pavers.map((paver) =>
        paver.id === id
          ? { ...paver, width: paver.height, height: paver.width }
          : paver
      )
    );
  };

  const canFit = (rectangle, x, y) => {
    return (
      x + rectangle.width <= largeRectangle.width &&
      y + rectangle.height <= largeRectangle.height
    );
  };

  const placePavers = (pavers) => {
    const grid = Array.from({ length: largeRectangle.height }, () =>
      Array(largeRectangle.width).fill(null)
    );
    const placements = [];

    for (const paver of pavers) {
      let placed = false;

      for (let y = 0; y < largeRectangle.height; y++) {
        if (placed) break;

        for (let x = 0; x < largeRectangle.width; x++) {
          if (canFit(paver, x, y)) {
            let canPlace = true;

            for (let dy = 0; dy < paver.height; dy++) {
              for (let dx = 0; dx < paver.width; dx++) {
                if (grid[y + dy][x + dx] !== null) {
                  canPlace = false;
                  break;
                }
              }
              if (!canPlace) break;
            }

            if (canPlace) {
              for (let dy = 0; dy < paver.height; dy++) {
                for (let dx = 0; dx < paver.width; dx++) {
                  grid[y + dy][x + dx] = paver;
                }
              }
              placements.push({ ...paver, x, y });
              placed = true;
              break;
            }
          }
        }
      }
    }

    return placements;
  };

  const renderPavers = () => {
    const placements = placePavers(pavers);

    return placements.map((paver) => (
      <Box
        key={paver.id}
        sx={{
          position: "absolute",
          top: paver.y,
          left: paver.x,
          width: paver.width,
          height: paver.height,
          border: "1px solid black",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {`${paver.width}x${paver.height}`}
      </Box>
    ));
  };

  const handleApplySizeChanges = () => {
    setLargeRectangle(tempRectangle);
  };

  return (
    <Box
      sx={{
        padding: 2,
        position: "relative",
        width: largeRectangle.width,
        height: largeRectangle.height,
      }}
    >
      <Box
        className="header"
        sx={{
          marginBottom: 3,
          display: "flex",
          justifyContent: "center",
          gap: 2,
          alignItems: "center",
          borderBottom: "1px solid lightgray",
          backgroundColor: "white",
          padding: 2,
        }}
      >
        <TextField
          label="Довжина, мм"
          variant="outlined"
          value={newPaver.width}
          onChange={(e) => setNewPaver({ ...newPaver, width: e.target.value })}
          type="number"
          sx={{ width: 130, height: 56 }}
        />
        <TextField
          label="Ширина, мм"
          variant="outlined"
          value={newPaver.height}
          onChange={(e) => setNewPaver({ ...newPaver, height: e.target.value })}
          type="number"
          sx={{ width: 120, height: 56 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddPaver}
          sx={{ height: 56 }}
        >
          Додати плитку
        </Button>
      </Box>

      {/* list of sizes  */}
      <Box
        className="pavers"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "center",
          padding: 2,
          backgroundColor: "white",
          borderBottom: "1px solid lightgray",
        }}
      >
        {pavers.map((paver) => (
          <Box
            key={paver.id}
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 1,
              backgroundColor: "lightgrey",
            }}
          >
            {`${paver.width}x${paver.height}`}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleRemovePaver(paver.id)}
            >
              Видалити
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRotatePaver(paver.id)}
            >
              Повернути
            </Button>
          </Box>
        ))}
      </Box>

      <Box
        className="large-rectangle-inputs"
        sx={{
          marginBottom: 3,
          display: "flex",
          justifyContent: "center",
          gap: 2,
          alignItems: "center",
          borderBottom: "1px solid lightgray",
          backgroundColor: "white",
          padding: 2,
        }}
      >
        <TextField
          label="Довжина площадки, мм"
          variant="outlined"
          value={tempRectangle.width}
          onChange={(e) =>
            setTempRectangle({
              ...tempRectangle,
              width: parseInt(e.target.value),
            })
          }
          type="number"
          sx={{ width: 180, height: 56 }}
        />
        <TextField
          label="Ширина площадки, мм"
          variant="outlined"
          value={tempRectangle.height}
          onChange={(e) =>
            setTempRectangle({
              ...tempRectangle,
              height: parseInt(e.target.value),
            })
          }
          type="number"
          sx={{ width: 180, height: 56 }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleApplySizeChanges}
          sx={{ height: 56 }}
        >
          Застосувати зміни розміру площадки
        </Button>
      </Box>

      <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
        {renderPavers()}
      </Box>
    </Box>
  );
};

export default PaverLayout;
